import {
	defineStore
} from "pinia";
import {
	menuMap
} from '@/common/menu';

export const storeMenu = defineStore("storeMenu", {
	state: () => {
		return {
			handler: 0,
			leftHandler: 0,
			menuKey: '',
			topKey: '',
			leftKey: '',
			topMenuMode: 'default', // default/small/none
			leftMenuMode: 'default', // default/small/none
			topActiveName: '',
			leftMenuOpenNames: [],
			leftMenuActiveName: '',
			breadcrumbList: [],
			breadcrumbListExt: [],
			buttonList: []
		};
	},
	getters: {},
	actions: {
		calcMode() {
			let topMenu = menuMap[this.topActiveName];
			if (topMenu) {
				this.leftMenuMode = topMenu.child > 0 ? this.topMenuMode : 'none';
				this.handler = Math.random();
			}
		},
		calcBreadcrumb() {
			this.breadcrumbList = [];
			this.breadcrumbListExt = [];
			let menu4 = menuMap[this.menuKey];
			if (menu4) {
				if (menu4.parent) {
					let menu3 = menuMap[menu4.parent];
					if (menu3) {
						if (menu3.parent) {
							let menu2 = menuMap[menu3.parent];
							if (menu2) {
								if (menu2.parent) {
									let menu1 = menuMap[menu2.parent];
									if (menu1) {
										this.breadcrumbList.push({
											"key": menu2.parent,
											"title": menu1.title,
											"link": menu1.link
										});
									}
								}
								this.breadcrumbList.push({
									"key": menu3.parent,
									"title": menu2.title,
									"link": menu2.link
								});
							}
						}
						this.breadcrumbList.push({
							"key": menu4.parent,
							"title": menu3.title,
							"link": menu3.link
						});
					}
				}
				this.breadcrumbList.push({
					"key": this.menuKey,
					"title": menu4.title,
					"link": menu4.link
				});
			}
		},
		calc(path) { // 路径格式：/console/alias1[-alias2][/alias3[-alias4][/other]]
			console.log("path", path)
			let pathArray = path.split('/');
			this.topKey = pathArray[1];
			this.topActiveName = pathArray[1];
			this.leftMenuOpenNames = [];
			if (pathArray.length > 2 && pathArray[2]) {
				this.leftKey = pathArray[2];
				this.menuKey = this.topKey + '-' + pathArray[2];
				this.leftMenuActiveName = pathArray[2];

				/* if (pathArray.length > 3 && pathArray[3]) {
				    this.menuKey = this.menuKey + '-' + pathArray[3];
				    this.leftMenuActiveName = this.leftMenuActiveName + '-' +  pathArray[3];
				} */

				if (pathArray[2].indexOf('-') > -1) {
					this.leftMenuOpenNames = pathArray[2].split('-');
					this.leftMenuOpenNames.splice(this.leftMenuOpenNames.length - 1, 1);
				}
			} else {
				this.leftKey = '';
				this.menuKey = this.topKey;
			}
			this.calcMode();
			this.calcBreadcrumb();
			this.handler = Math.random();
		},
		addButton(item) {
			this.buttonList.push(item);
		},
		setButton(list) {
			this.buttonList = list;
		},
		addBreadcrumbListExt(item) {
			this.breadcrumbListExt.push(item);
		},
		setBreadcrumbListExt(list) {
			this.breadcrumbListExt = list;
		},
	}
});
let router = [{
		path: 'agent',
		name: 'agent',
		meta: {
			title: '代理商管理'
		},
		component: () => import('@/views/user/agent/List.vue')
	},
	{
		path: 'agent/wx_applet',
		name: 'agent__wx_applet',
		meta: {
			title: '专属小程序'
		},
		component: () => import('@/views/weixin/WxApplet.vue')
	}, {
		path: 'agent/role',
		name: 'agent__role',
		meta: {
			title: '角色列表'
		},
		component: () => import('@/views/user/agent/RoleList.vue')
	}, {
		path: 'agent/user',
		name: 'agent__user',
		meta: {
			title: '账号列表'
		},
		component: () => import('@/views/user/agent/UserList.vue')
	}, {
		path: 'agent/operator',
		name: 'agent__operator',
		meta: {
			title: '运营商列表'
		},
		component: () => import('@/views/user/agent/OperatorList.vue')
	}, {
		path: 'operator/wx_applet',
		name: 'operator__wx_applet',
		meta: {
			title: '专属小程序'
		},
		component: () => import('@/views/weixin/WxApplet.vue')
	}, {
		path: 'agent_role',
		name: 'agent_role',
		meta: {
			title: '角色配置'
		},
		component: () => import('@/views/user/agentRole/List.vue')
	}, {
		path: 'agent_user',
		name: 'agent_user',
		meta: {
			title: '账号列表'
		},
		component: () => import('@/views/user/agentUser/List.vue')
	}, {
		path: 'operator',
		name: 'operator',
		meta: {
			title: '运营商管理'
		},
		component: () => import('@/views/user/operator/List.vue')
	}, {
		path: 'operator/role',
		name: 'operator__role',
		meta: {
			title: '角色列表'
		},
		component: () => import('@/views/user/operator/RoleList.vue')
	}, {
		path: 'operator/user',
		name: 'operator__user',
		meta: {
			title: '账号列表'
		},
		component: () => import('@/views/user/operator/UserList.vue')
	}, {
		path: 'operator/area',
		name: 'operator__area',
		meta: {
			title: '区域管理'
		},
		component: () => import('@/views/user/operator/AreaTree.vue')
	}, {
		path: 'operator/area_room',
		name: 'operator__area_room',
		meta: {
			title: '机房管理'
		},
		component: () => import('@/views/user/operator/AreaRoomTree.vue')
	}, {
		path: 'operator/appkey',
		name: 'operator__appkey',
		meta: {
			title: '第三方接入'
		},
		component: () => import('@/views/user/operator/AppkeyList.vue')
	}, {
		path: 'operator/partner',
		name: 'operator__partner',
		meta: {
			title: '合伙人'
		},
		component: () => import('@/views/user/operator/PartnerList.vue')
	}, {
		path: 'operator_role',
		name: 'operator_role',
		meta: {
			title: '角色配置'
		},
		component: () => import('@/views/user/operatorRole/List.vue')
	}, {
		path: 'operator_user',
		name: 'operator_user',
		meta: {
			title: '账号列表'
		},
		component: () => import('@/views/user/operatorUser/List.vue')
	}, {
		path: 'admin',
		name: 'admin',
		meta: {
			title: '账号列表'
		},
		component: () => import('@/views/user/admin/List.vue')
	}, {
		path: 'admin_role',
		name: 'admin_role',
		meta: {
			title: '角色配置'
		},
		component: () => import('@/views/user/adminRole/List.vue')
	}, {
		path: 'operator_area',
		name: 'operator_area',
		meta: {
			title: '区域管理'
		},
		component: () => import('@/views/user/operatorArea/Tree.vue')
	}, {
		path: 'operator_area_room',
		name: 'operator_area_room',
		meta: {
			title: '机房管理'
		},
		component: () => import('@/views/user/operatorAreaRoom/Tree.vue')
	}, {
		path: 'large_screen_setting',
		name: 'large_screen_setting',
		meta: {
			title: '智慧大屏设置'
		},
		component: () => import('@/views/user/operatorLargeScreenSetting/Index.vue')
	}, {
		path: 'operator_setting',
		name: 'operator_setting',
		meta: {
			title: '系统设置'
		},
		component: () => import('@/views/user/operatorSetting/Index.vue')
	}, {
		path: 'website',
		name: 'website',
		meta: {
			title: '定制站点'
		},
		component: () => import('@/views/system/website/List.vue')
	}, {
		path: 'log',
		name: 'log',
		meta: {
			title: '日志'
		},
		component: () => import('@/views/log/sysLog/List.vue')
	}, {
		path: 'question',
		name: 'question',
		meta: {
			title: '常见问题'
		},
		component: () => import('@/views/system/question/List.vue')
	}, {
		path: 'water_test_report',
		name: 'water_test_report',
		meta: {
			title: '水质检测报告'
		},
		component: () => import('@/views/system/waterTestReport/Tree.vue')
	}, {
		path: 'wxmp-autoreply',
		name: 'wxmp-autoreply',
		meta: {
			title: '被关注回复'
		},
		component: () => import('@/views/system/wxmp/WxAddFriendAutoreplyInfo.vue')
	}, {
		path: 'wxmp-menu',
		name: 'wxmp-menu',
		meta: {
			title: '自定义菜单'
		},
		component: () => import('@/views/system/wxmp/WxMenu.vue')
	}, {
		path: 'setting-area',
		name: 'setting-area',
		meta: {
			title: '地区'
		},
		component: () => import('@/views/system/setting/AreaList.vue')
	}, {
		path: 'setting-purview',
		name: 'setting-purview',
		meta: {
			title: '权限'
		},
		component: () => import('@/views/system/setting/PurviewList.vue')
	}, {
		path: 'setting-monitor',
		name: 'setting-monitor',
		meta: {
			title: '服务器'
		},
		component: () => import('@/views/system/setting/MonitorAddressList.vue')
	}, {
		path: 'setting-aep_product',
		name: 'setting-aep_product',
		meta: {
			title: '电信NB产品'
		},
		component: () => import('@/views/system/setting/AepProductList.vue')
	}, {
		path: 'setting-ota',
		name: 'setting-ota',
		meta: {
			title: '固件'
		},
		component: () => import('@/views/system/setting/OtaList.vue')
	}, {
		path: 'setting-report',
		name: 'setting-report',
		meta: {
			title: '报表'
		},
		component: () => import('@/views/system/setting/ReportSuper.vue')
	}
];
export default router;
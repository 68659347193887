export default {
	"alias": "device",
	"customIcon": "icon-xitong",
	"title": "设备",
	"pos": "top",
	"purview": [
		"device",
		"device_warning",
		"device_log",
		"work_order",
		"device_ota_setting",
		["device_codes", 1],
		["device_batch_add", 1],
		"test_device1",
		"test_device2",
		"test_device3",
		"test_device_log"
	],
	"menus": [{
		"alias": "device",
		"customIcon": "icon-shujubiao",
		"title": "设备管理",
		"pos": "left",
		"purview": "device",
		"type": "submenu",
		"menus": [{
			"alias": "m8",
			"title": "直饮机",
			"purview": "device",
			"link": "/device/device-m8"
		}, {
			"alias": "m9",
			"title": "采集器",
			"purview": "device",
			"link": "/device/device-m9"
		}, {
			"alias": "m10",
			"title": "有线水表",
			"purview": "device",
			"link": "/device/device-m10"
		}, {
			"alias": "m10nb",
			"title": "NB水表",
			"purview": "device",
			"link": "/device/device-m10nb"
		}, {
			"alias": "m11",
			"title": "PLC",
			"purview": "device",
			"link": "/device/device-m11"
		}]
	}, {
		"alias": "warning",
		"customIcon": "icon-baojing",
		"title": "设备报警",
		"pos": "left",
		"purview": "device_warning",
		"type": "submenu",
		"menus": [{
			"alias": "m8",
			"title": "直饮机报警",
			"purview": "device_warning",
			"badge": "device_warning_m8",
			"link": "/device/warning-m8"
		}, {
			"alias": "m9",
			"title": "采集器报警",
			"purview": "device_warning",
			"badge": "device_warning_m9",
			"link": "/device/warning-m9"
		}, {
			"alias": "m10",
			"title": "有线水表报警",
			"purview": "device_warning",
			"badge": "device_warning_m10",
			"link": "/device/warning-m10"
		}, {
			"alias": "m10nb",
			"title": "NB水表报警",
			"purview": "device_warning",
			"badge": "device_warning_m10nb",
			"link": "/device/warning-m10nb"
		}, {
			"alias": "m11",
			"title": "PLC报警",
			"purview": "device_warning",
			"badge": "device_warning_m11",
			"link": "/device/warning-m11"
		}]
	}, {
		"alias": "device_map",
		"customIcon": "icon-ditu",
		"title": "设备地图",
		"pos": "left",
		"purview": "device",
		"link": "/device/device_map"
	}, {
		"alias": "device_log",
		"customIcon": "icon-renwu",
		"title": "操作日志",
		"pos": "left",
		"purview": "device_log",
		"link": "/device/device_log"
	}, {
		"alias": "device_cmd",
		"customIcon": "icon-fuzhi",
		"title": "指令日志",
		"pos": "left",
		"purview": "device_log",
		"link": "/device/device_cmd"
	}, {
		"alias": "device_warning",
		"customIcon": "icon-wenjian",
		"title": "报警记录",
		"pos": "left",
		"purview": "device_warning",
		"link": "/device/device_warning"
	}, {
		"alias": "work_order",
		"customIcon": "icon-chilun",
		"title": "故障报修",
		"pos": "left",
		"purview": "work_order",
		"link": "/device/work_order"
	}, {
		"alias": "free",
		"customIcon": "icon-banping",
		"title": "游离设备",
		"pos": "left",
		"link": ""
	}, {
		"alias": "sim",
		"customIcon": "icon-shenfen",
		"title": "流量卡",
		"pos": "left",
		"type": "submenu",
		"menus": [{
			"alias": "list",
			"title": "全部列表",
			"link": ""
		}, {
			"alias": "history",
			"title": "使用记录",
			"link": ""
		}]
	}, {
		"alias": "model",
		"customIcon": "icon-xitong",
		"title": "设备型号",
		"pos": "left",
		"purview": "___is_root___",
		"link": "/device/model"
	}, {
		"alias": "ota_setting",
		"customIcon": "icon-chuangkou",
		"title": "设备固件",
		"pos": "left",
		"purview": "device_ota_setting",
		"link": "/device/ota_setting"
	}, {
		"alias": "device_codes",
		"customIcon": "icon-miaobiao",
		"title": "三码表",
		"pos": "left",
		"purview": [
			["device_codes", 1]
		],
		"link": "/device/device_codes"
	}, {
		"alias": "device_batch_record",
		"customIcon": "icon-youchuan",
		"title": "导入设备",
		"pos": "left",
		"purview": [
			["device_batch_add", 1]
		],
		"link": "/device/device_batch_record"
	}, {
		"alias": "test",
		"customIcon": "icon-shenfen",
		"title": "生产测试",
		"pos": "left",
		"purview": ["test_device1", "test_device2", "test_device3", "test_device_log"],
		"type": "submenu",
		"menus": [{
			"alias": "s1",
			"title": "编号写入",
			"purview": "test_device1",
			"link": "/device/test-s1"
		}, {
			"alias": "s2",
			"title": "电控测试",
			"purview": "test_device2",
			"link": "/device/test-s2"
		}, {
			"alias": "s3",
			"title": "组装测试",
			"purview": "test_device3",
			"link": "/device/test-s3"
		}, {
			"alias": "log",
			"title": "测试记录",
			"purview": "test_device_log",
			"link": "/device/test-log"
		}]
	}]
}
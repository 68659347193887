export default {
	"alias": "finance",
	"customIcon": "icon-qianbao",
	"title": "财务",
	"pos": "top",
	"purview": [
		["airelay_view", 3],
		["airelay_withdraw", 3],
		["airelay_order", 3],
		["airelay_qrcode_view", 3],
		["authorized_cash_record", 3],
		["trade", 3]
	],
	"menus": [{
		"alias": "airelay",
		"customIcon": "icon-qianbao",
		"title": "睿付通钱包",
		"pos": "left",
		"purview": [
			["airelay_view", 3],
			["airelay_withdraw", 3],
			["airelay_order", 3],
			["airelay_qrcode_view", 3],
		],
		"type": "submenu",
		"menus": [{
			"alias": "index",
			"title": "资金账户",
			"purview": [
				["airelay_view", 3]
			],
			"link": "/finance/airelay-index"
		}, {
			"alias": "withdraw",
			"title": "提现记录",
			"purview": [
				["airelay_withdraw", 3]
			],
			"link": "/finance/airelay-withdraw"
		}, {
			"alias": "order",
			"title": "交易记录",
			"purview": [
				["airelay_order", 3]
			],
			"link": "/finance/airelay-order"
		}]
	}, {
		"alias": "authorized_cash_record",
		"customIcon": "icon-shenfen",
		"title": "授权记录",
		"pos": "left",
		"purview": [
			["authorized_cash_record", 3]
		],
		"link": "/finance/authorized_cash_record"
	}, {
		"alias": "trade",
		"customIcon": "icon-shenfen",
		"title": "订单记录",
		"pos": "left",
		"purview": [
			["trade", 3]
		],
		"link": "/finance/trade"
	}]
}
import {
	menuList,
	menuMap,
	leftMenuList
} from '@/common/menu';

// 初始化菜单
const initMenu = function() {
	// 初始化顶部菜单Map
	for (let i in menuList) {
		let menu = menuList[i];
		if (menu.alias) {
			menuMap[menu.alias] = {
				"icon": menu.icon,
				"title": menu.title,
				"alias": menu.alias,
				"pos": menu.pos,
				"type": menu.type,
				"purview": menu.purview,
				"link": menu.link,
				"child": 0
			};
			if (menu.menus && menu.menus.length > 0) {
				for (let j in menu.menus) {
					let menu2 = menu.menus[j];
					if (menu2.alias) {
                        
						menuMap[menu.alias + "-" + menu2.alias] = {
							"root": menu.alias,
							"parent": menu.alias,
							"icon": menu2.icon,
							"title": menu2.title,
							"alias": menu2.alias,
							"pos": menu2.pos,
							"type": menu2.type,
							"purview": menu2.purview,
							"link": menu2.link,
							"child": 0
						};
						if (menu2.pos == 'left') {
							menu2.top = menu.alias;
							leftMenuList.push(menu2);
							menuMap[menu2.top].child++;
						}
						if (menu2.menus && menu2.menus.length > 0) {
							for (let k in menu2.menus) {
								let menu3 = menu2.menus[k];
								if (menu3.alias) {
									menuMap[menu.alias + "-" + menu2.alias + "-" + menu3.alias] = {
										"root": menu.alias,
										"parent": menu.alias + "-" + menu2.alias,
										"icon": menu3.icon,
										"title": menu3.title,
										"alias": menu3.alias,
										"pos": menu3.pos,
										"type": menu3.type,
										"purview": menu3.purview,
										"link": menu3.link
									};
									if (menu3.pos == 'left') {
										menu3.top = menu.alias + "-" + menu2.alias;
										leftMenuList.push(menu3);
										menuMap[menu3.top].child++;
									}
									if (menu3.menus && menu3.menus.length > 0) {
										for (let l in menu3.menus) {
											let menu4 = menu3.menus[l];
											if (menu4.alias) {
												menuMap[menu.alias + "-" + menu2.alias + "-" + menu3.alias + "-" +
													menu4.alias] = {
													"root": menu.alias,
													"parent": menu.alias + "-" + menu2.alias + "-" + menu3
														.alias,
													"icon": menu4.icon,
													"title": menu4.title,
													"alias": menu4.alias,
													"pos": menu4.pos,
													"type": menu4.type,
													"purview": menu4.purview,
													"link": menu4.link
												};
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

export {
	initMenu,
	menuMap
}

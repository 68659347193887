import axios from 'axios'
import qs from "qs"
import {
	storeBase
} from '@/store/base'
import {
	Notice
} from 'view-ui-plus'

// 创建 axios 实例
const request = axios.create({
	baseURL: import.meta.env.APP_API_BASE_URL, // API 请求的默认前缀
	timeout: 6000, // 请求超时时间
	// withCredentials: true, // 异步请求携带cookie
	headers: { // 设置后端需要的传参类型
		// 'X-Requested-With': 'XMLHttpRequest',
	},
})

// 异常拦截处理器
const errorHandler = (error) => {
	console.log('errorHandler', error)
	if (error.response) {
		const store = storeBase();
		const data = error.response.data
		// 从 localstorage 获取 token
		const token = store.token
		if (error.response.status === 404) {
			Notice.error({
				title: 'unknown',
				desc: data.message
			})
		}
		if (error.response.status === 403) {
			Notice.error({
				title: 'Forbidden',
				desc: data.message
			})
		}
		if (error.response.status === 401) {
			Notice.error({
				title: 'Unauthorized',
				desc: 'Authorization verification failed'
			})
			if (token) {
				// store.dispatch('Logout').then(() => {
				// 	setTimeout(() => {
				// 		window.location.reload()
				// 	}, 1500)
				// })
			}
		}
	}
	return Promise.reject(error)
}

request.interceptors.request.use(config => {
	const store = storeBase();
	const token = store.token
	if (token) {
		config.headers['token'] = token
	}
	config.headers['uuid'] = store.getUUID()
	config.headers['f'] =
		import.meta.env.APP_CLIENT
	config.headers['v'] =
		import.meta.env.APP_VERSION
	config.headers['c'] =
		import.meta.env.APP_VERSION_CODE
	config.headers['l'] = store.getLang()
	if (config.contentType) {
		config.headers['content-type'] = config.contentType
	}
	console.log('request: ', config)
	return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
	console.log('response: ', response)
	if (response.data?.code == 1004) {
		const store = storeBase();
		store.token = '';
		window.location.reload();
	}
	return response.data === undefined ? {} : response.data
}, errorHandler)

export default request

const httpGet = (url, parameter = {}) => {
	return request({
		url: url,
		method: 'get',
		contentType: 'application/x-www-form-urlencoded',
		params: parameter
	})
}
const httpPost = (url, parameter = {}) => {
	return request({
		url: url,
		method: 'post',
		contentType: 'application/x-www-form-urlencoded',
		data: qs.stringify(parameter)
	})
}
const httpPostJson = (url, parameter = {}) => {
	return request({
		url: url,
		method: 'post',
		contentType: 'application/json',
		data: parameter
	})
}
const httpPostMultipart = (url, parameter = {}) => {
	return request({
		url: url,
		method: 'post',
		contentType: 'multipart/form-data',
		data: parameter
	})
}

export {
	httpGet,
	httpPost,
	httpPostJson,
	httpPostMultipart
}
<style lang="less" scoped>
.breadcrumb {
	margin: 10px 20px;
	&-item {
		color: #515a6e;
		cursor: pointer;
	}
}
</style>

<template>
	<Row v-if="!hideBreadcrumb" class-name="breadcrumb">
		<Col span="12">
			<Breadcrumb separator="<span style='color: #515a6e;'>/</span>" style="line-height: 32px;">
				<BreadcrumbItem v-for="(item, index) in storeMenu.breadcrumbList" :key="item.key" class="breadcrumb-item" :to="item.link" @click="handleClick(item, index)">
					{{ item.title }}
				</BreadcrumbItem>
				<BreadcrumbItem v-for="(item, index) in storeMenu.breadcrumbListExt" :key="item.key" class="breadcrumb-item" :to="item.link" @click="handleClick(item, index)">
					{{ item.title }}
				</BreadcrumbItem>
			</Breadcrumb>
		</Col>
		<Col span="12" style="text-align: right;">
			<template v-for="(item, index) in storeMenu.buttonList">
				<Button
					:type="item.type || 'primary'"
					:ghost="item.ghost"
					:icon="item.icon"
					:custom-icon="item.customIcon ? 'iconfont ' + item.customIcon : ''"
					shape="circle"
					:to="item.to"
					:target="item.target"
					style="margin-right:10px;min-width: 120px;"
					@click="item.click"
					>{{ item.title }}
				</Button>
			</template>
		</Col>
	</Row>
</template>

<script>
import { storeMenu } from '@/store/menu';
export default {
	name: 'TopBreadcrumb',
	data() {
		return {
			storeMenu: storeMenu(),
		};
	},
	computed: {
		hideBreadcrumb() {
			return this.$route.meta && this.$route.meta.hideBreadcrumb;
		},
	},
	methods: {
		handleClick(item, index) {
			console.log('Breadcrumb', item);
			if (!item.link) {
				// 处理点击事件
			}
		},
	},
};
</script>

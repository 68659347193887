let router = [{
	path: 'report_trade',
	name: 'report_trade',
	meta: {
		title: '收入报表'
	},
	component: () => import('@/views/report/ReportTrade.vue')
}, {
	path: 'report_consume',
	name: 'report_consume',
	meta: {
		title: '消费报表'
	},
	component: () => import('@/views/report/ReportConsume.vue')
}, {
	path: 'report_device',
	name: 'report_device',
	meta: {
		title: '设备报表'
	},
	component: () => import('@/views/report/ReportDevice.vue')
}, {
	path: 'report_room',
	name: 'report_room',
	meta: {
		title: '用户报表'
	},
	component: () => import('@/views/report/ReportRoom.vue')
}, {
	path: 'report_area',
	name: 'report_area',
	meta: {
		title: '区域报表'
	},
	component: () => import('@/views/report/ReportArea.vue')
}];
export default router;
import {
	defineStore
} from 'pinia'
export const storeUI = defineStore('storeUI', {
	state: () => {
		return {
			theme: '',
			header: {
				screenList: []
			}
		}
	},
	persist: {
		enabled: true,
		strategies: [{
			key: 'sfcloud_manage_ui',
		}]
	},
	getters: {},
	actions: {}
})
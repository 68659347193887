export default {
	"alias": "report",
	"customIcon": "icon-bingzhuangtu",
	"title": "报表",
	"pos": "top",
	"purview": [
		"report_trade",
		"report_consume",
		"report_device",
		"report_room",
		"report_area"
	],
	"menus": [{
		"alias": "report_trade",
		"customIcon": "icon-qianbao",
		"title": "收入报表",
		"pos": "left",
		"purview": "report_trade",
		"link": "/report/report_trade"
	}, {
		"alias": "report_consume",
		"customIcon": "icon-fuzhi",
		"title": "消费报表",
		"pos": "left",
		"purview": "report_consume",
		"link": "/report/report_consume"
	}, {
		"alias": "report_device",
		"customIcon": "icon-renwu",
		"title": "设备报表",
		"pos": "left",
		"purview": "report_device",
		"link": "/report/report_device"
	}, {
		"alias": "report_room",
		"customIcon": "icon-yonghubiao",
		"title": "用户报表",
		"pos": "left",
		"purview": "report_room",
		"link": "/report/report_room"
	}, {
		"alias": "report_area",
		"customIcon": "icon-ditu",
		"title": "区域报表",
		"pos": "left",
		"purview": "report_area",
		"link": "/report/report_area"
	}]
}
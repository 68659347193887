<template>
	<div :id="'theme_' + uiTheme">
		<router-view />
		<Spin size="large" fix :show="spinShow"></Spin>
	</div>
</template>

<script>
import { ref } from 'vue';
import { storeBase } from '@/store/base';
import { storeUI } from '@/store/ui';
import { storeDevice } from '@/store/device';
import { storeMenu } from '@/store/menu';
import { initMenu } from '@/utils/menu';
import { updateWindowTitle } from '@/utils/util';
import CommonLib from '@/common/index';
export default {
	name: 'App',
	data() {
		return {
			storeBase: storeBase(),
			storeUI: storeUI(),
			storeDevice: storeDevice(),
			storeMenu: storeMenu(),
			uiTheme: 'default',
			spinShow: true,
		};
	},
	methods: {
		loadTheme(theme) {
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = '/static/theme/' + theme + '/theme.css?r=' + import.meta.env.APP_VERSION_CODE;
			document.querySelectorAll('link[rel="stylesheet"]').forEach((style) => {
				if (style.href.includes('theme')) {
					document.head.removeChild(style);
				}
			});
			document.head.appendChild(link);
		},
	},
	beforeCreate() {
		CommonLib.api.get_ui_data().then((res) => {
			console.log('get_ui_data', res.resultExt);
			if (res.success) {
				if (res.resultExt) {
					this.storeBase.projectName = res.resultExt.projectName;
					this.uiTheme = res.resultExt['ui.theme'] || 'default';
					if (res.resultExt['ui.header.screenList']) {
						this.storeUI.header.screenList = JSON.parse(res.resultExt['ui.header.screenList']);
					}
				}
			}
			if (!this.storeBase.projectName) {
				this.storeBase.projectName = '智饮云';
			}
			updateWindowTitle(this.$route.meta?.title, this.storeBase.projectName);
			this.storeUI.theme = this.uiTheme;
			this.loadTheme(this.uiTheme);
			this.spinShow = false;
		});
	},
	created() {
		initMenu();
		this.$Loading.config({
			color: '#19be6b',
		});
		this.$Message.config({
			duration: 3,
		});
		this.$Notice.config({
			duration: 10,
		});
	},
	mounted() {
		this.storeBase.clientWidth = document.documentElement.clientWidth;
		this.storeBase.clientHeight = document.documentElement.clientHeight;
		window.onresize = () => {
			this.storeBase.clientWidth = document.documentElement.clientWidth;
			this.storeBase.clientHeight = document.documentElement.clientHeight;
		};
		document.onkeydown = (e) => {
			this.storeBase.updateKeyboard(e, true);
		};
		document.onkeyup = (e) => {
			this.storeBase.updateKeyboard(e, false);
		};
		// 初始化系统数据
		if (this.storeBase.badgeMap.dataTime == 0) {
			this.storeBase.badgeMap.dataTime = new Date().getTime();
			CommonLib.api
				.get_extend_data({
					mode:
						'userinfo,deviceModelList,deviceModelConfigList,roomInsufficientBalance,roomUnusedWater,deviceWarningM8,deviceWarningM9,deviceWarningM10,deviceWarningM10NB,deviceWarningM11',
				})
				.then((res) => {
					if (res.success) {
						if (res.resultExt) {
							this.storeBase.userinfo = res.resultExt.userinfo || {};
							this.storeBase.badgeMap.room_insufficient_balance = res.resultExt?.roomInsufficientBalance;
							this.storeBase.badgeMap.room_unused_water = res.resultExt?.roomUnusedWater;
							this.storeBase.badgeMap.device_warning_m8 = res.resultExt?.deviceWarningM8;
							this.storeBase.badgeMap.device_warning_m9 = res.resultExt?.deviceWarningM9;
							this.storeBase.badgeMap.device_warning_m10 = res.resultExt?.deviceWarningM10;
							this.storeBase.badgeMap.device_warning_m10nb = res.resultExt?.deviceWarningM10NB;
							this.storeBase.badgeMap.device_warning_m11 = res.resultExt?.deviceWarningM11;
							this.storeDevice.modelList = res.resultExt.deviceModelList || [];
							this.storeDevice.modelConfigList = res.resultExt.deviceModelConfigList || [];
							this.storeMenu.handler = Math.random();
						}
					}
				});
		}
	},
};
</script>

<style lang="less">
@import '/public/assets/font/iconfont.css';

*[class*='-selected'] {
	.icon-tuceng:before {
		content: '\e688';
	}
	.icon-xitong:before {
		content: '\e6b2';
	}
	.icon-yonghubiao:before {
		content: '\e6b8';
	}
	.icon-bingzhuangtu:before {
		content: '\e6b9';
	}
	.icon-shujubiao:before {
		content: '\e6b4';
	}
	.icon-chilun:before {
		content: '\e6b5';
	}
	.icon-qianbao:before {
		content: '\e6b3';
	}
}
.btn-bg {
	background-image: @bg-linear-color !important;
}
.list-dropdown {
	max-height: none !important;
}
.list-address-city {
	color: @list-address-city-color;
}
.list-address-district {
	color: @list-address-district-color;
}
.list-address-parentArea {
	color: @list-address-parentArea-color;
}
.list-address-area {
	color: @list-address-area-color;
}
.form-search .input-text {
	width: 200px;
}
.alert {
	margin-top: -15px;
	padding-right: 16px;
}

/*定义滚动条样式*/
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: fade(@header-trigger-bg, 10%);
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: fade(@header-trigger-bg, 10%);
}
::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
	background-color: fade(@header-trigger-bg, 80%);
}
</style>

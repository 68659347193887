<style lang="less" scoped>
.transition-box {
	height: 64px;
	padding-top: 7px;
	text-align: center;
	background-color: @primary-color;
	.logo {
		width: 200px;
		height: 50px;
	}
	.mini-logo {
		width: 50px;
		height: 50px;
	}
}
.header {
	color: @header-text-color;
	height: 64px;
	.logo {
		background-color: #1b1c22;
		background: url(@/assets/bg_logo.png) no-repeat center center;
		height: 64px;
		width: 200px;
		transition: all 0.2s ease-in-out;
		span {
			display: block;
			width: 106px;
			margin: 12px auto 0;
			height: 40px;
		}
	}
	.logo.active {
		width: 78px;
		span {
			width: 54px;
		}
	}
	&-trigger {
		width: auto;
		padding: 0 12px;
		text-align: center;
		cursor: pointer;
		&-icon {
			transition: all 0.3s;
			font-size: 18px;
		}
		&-image {
			height: 14px;
		}
	}
	&-trigger:hover {
	}
}
.rotate-icon {
	transform: rotate(-90deg);
}
</style>

<template>
	<Row class="header">
		<i-Col class="logo " :class="isCollapsed ? 'active' : ''">
			<span></span>
		</i-Col>
		<i-Col flex="50px" @click="toggleCollapsed">
			<div class="header-trigger"><Icon type="md-menu" class="header-trigger-icon" :class="isCollapsed ? 'rotate-icon' : ''" /></div>
		</i-Col>
		<i-Col flex="700px"><TopMenu /></i-Col>
		<i-Col flex="auto" style="text-align: right;">
			<span v-if="serverMode" style="color: #ed4014;margin-right: 10px;">{{ serverMode }}</span>
			<span v-if="version" style="color: #f28a13;">{{ version }}</span>
			<Dropdown transfer class="header-trigger" @on-click="userBtn">
				<span style="padding-left:10px;">
					{{ userinfo.nickname }}
					<Icon type="ios-arrow-down"></Icon>
				</span>
				<template #list>
					<DropdownMenu>
						<DropdownItem name="changepwd">
							<Icon custom="iconfont icon-bianji" size="18" />
							修改密码
						</DropdownItem>
						<DropdownItem name="logout">
							<Icon custom="iconfont icon-zuochuan" size="18" />
							全部退出
						</DropdownItem>
						<DropdownItem name="lock">
							<Icon custom="iconfont icon-lock" size="18" />
							退出登录
						</DropdownItem>
					</DropdownMenu>
				</template>
			</Dropdown>
			<template v-if="storeBase.checkPurview('large_screen')">
				<template v-if="headerScreenList.length > 0">
					<Dropdown transfer class="header-trigger">
						<span style="padding-left:10px;">
							智慧大屏
							<Icon type="ios-arrow-down"></Icon>
						</span>
						<template #list>
							<DropdownMenu>
								<template v-for="(item, index) in headerScreenList">
									<router-link :to="{ path: item.link, query: { t: Math.random() } }" :target="item.target">
										<DropdownItem :name="item.alias">
											{{ item.name }}
										</DropdownItem>
									</router-link>
								</template>
							</DropdownMenu>
						</template>
					</Dropdown>
				</template>
				<template v-else>
					<router-link :to="{ path: '/screen/' }" target="_blank">智慧大屏</router-link>
				</template>
			</template>
		</i-Col>
	</Row>
</template>

<script>
import { storeBase } from '@/store/base';
import { storeUI } from '@/store/ui';
import { storeMenu } from '@/store/menu';
import Websocket from '@/utils/websocket';
import CommonLib from '@/common/index';
import TopMenu from '@/layout/components/TopMenu';
export default {
	name: 'HeaderLayout',
	components: {
		TopMenu,
	},
	data() {
		return {
			storeBase: storeBase(),
			storeUI: storeUI(),
			storeMenu: storeMenu(),
		};
	},
	computed: {
		isCollapsed() {
			return this.storeMenu.topMenuMode != 'default';
		},
		language() {
			return this.storeBase.language;
		},
		userinfo() {
			return this.storeBase.userinfo;
		},
		serverMode() {
			let name = import.meta.env.APP_ENV_NAME;
			if (name && this.storeUI.theme == 'default') {
				return '【' + name + '】';
			} else {
				return '';
			}
		},
		version() {
			let version = CommonLib.config.userType['k' + this.storeBase.userType]?.title;
			if (version) {
				version += '版（';
			} else {
				version = '版本（';
			}
			return version + import.meta.env.APP_VERSION + '）';
		},
		headerScreenList() {
			return this.storeUI.header.screenList;
		},
	},
	methods: {
		toggleCollapsed() {
			if (this.storeMenu.topMenuMode == 'default') {
				this.storeMenu.topMenuMode = 'small';
				if (this.storeMenu.leftMenuMode != 'none') {
					this.storeMenu.leftMenuMode = 'small';
				}
			} else {
				this.storeMenu.topMenuMode = 'default';
				if (this.storeMenu.leftMenuMode != 'none') {
					this.storeMenu.leftMenuMode = 'default';
				}
			}
		},
		userBtn(name) {
			if (name == 'changepwd') {
				this.$router.push({
					path: '/console/changepwd',
				});
			} else if (name == 'lock') {
				this.lock();
			} else if (name == 'logout') {
				this.logout();
			}
		},
		lock() {
			CommonLib.api.lock({}).then((res) => {
				this.storeBase.token = '';
				this.storeBase.userinfo = {};
				this.$router.push({
					path: '/',
				});
			});
		},
		logout() {
			CommonLib.api.logout({}).then((res) => {
				this.storeBase.token = '';
				this.storeBase.userinfo = {};
				this.$router.push({
					path: '/',
				});
				Websocket.unconnect();
			});
		},
	},
	mounted() {},
};
</script>

let router = [{
	path: 'charge-option',
	name: 'charge-option',
	meta: {
		title: '充值套餐 - 套餐设置'
	},
	component: () => import('@/views/marketing/chargeOptionGroup/List.vue')
}, {
	path: 'charge-area',
	name: 'charge-area',
	meta: {
		title: '充值套餐 - 区域设置'
	},
	component: () => import('@/views/marketing/operatorAreaCombo/List.vue')
}, {
	path: 'charge1-option',
	name: 'charge1-option',
	meta: {
		title: '时效套餐 - 套餐设置'
	},
	component: () => import('@/views/marketing/chargeOptionGroup1/List.vue')
}, {
	path: 'charge1-area',
	name: 'charge1-area',
	meta: {
		title: '时效套餐 - 区域设置'
	},
	component: () => import('@/views/marketing/operatorAreaCombo1/List.vue')
}, {
	path: 'charge2-option',
	name: 'charge2-option',
	meta: {
		title: '首充优惠 - 套餐设置'
	},
	component: () => import('@/views/marketing/chargeOptionGroup2/List.vue')
}, {
	path: 'charge2-area',
	name: 'charge2-area',
	meta: {
		title: '首充优惠 - 区域设置'
	},
	component: () => import('@/views/marketing/operatorAreaCombo2/List.vue')
}, {
	path: 'trial-option',
	name: 'trial-option',
	meta: {
		title: '限时试用 - 活动设置'
	},
	component: () => import('@/views/marketing/trialOption/List.vue')
}, {
	path: 'trial-area',
	name: 'trial-area',
	meta: {
		title: '限时试用 - 区域设置'
	},
	component: () => import('@/views/marketing/operatorAreaTrial/List.vue')
}];
export default router;
let router = [{
	path: 'airelay-index',
	name: 'airelay-index',
	meta: {
		title: '资金账户'
	},
	component: () => import('@/views/finance/airelayPay/Index.vue')
}, {
	path: 'airelay-withdraw',
	name: 'airelay-withdraw',
	meta: {
		title: '提现记录'
	},
	component: () => import('@/views/finance/airelayPay/WithdrawList.vue')
}, {
	path: 'airelay-order',
	name: 'airelay-order',
	meta: {
		title: '交易记录'
	},
	component: () => import('@/views/finance/airelayPay/OrderList.vue')
}, {
	path: 'authorized_cash_record',
	name: 'authorized_cash_record',
	meta: {
		title: '授权记录'
	},
	component: () => import('@/views/finance/authorizedCashRecord/List.vue')
}, {
	path: 'trade',
	name: 'trade',
	meta: {
		title: '订单记录'
	},
	component: () => import('@/views/trade/trade/Tree.vue')
}];
export default router;
<style lang="less" scoped>
.left-menu-a {
	.left-icon {
		font-size: 18px;
	}
	&:after {
		background: none !important;
	}
	.left-menu-1,
	.left-submenu-1,
	.left-menu-2,
	.left-submenu-2,
	.left-menu-3 {
		color: @left-text-color;
		white-space: nowrap;
	}
	.left-menu-1:after,
	.left-menu-2:after,
	.left-menu-3:after {
		background: none !important;
	}
	.ivu-menu-opened {
		background-color: @left-menu-bg !important;
		.ivu-menu-item,
		.ivu-menu-item-selected {
		}
		.ivu-menu-item.ivu-menu-item-active.ivu-menu-item-selected {
			background-image: @bg-linear-color !important;
		}
	}
}
.left-menu-b {
	&:after {
		background: none !important;
	}
	.left-menu-1 {
		color: @primary-color;
	}
	.ivu-menu-item-selected {
		background-color: @left-menu-bg;
		&:after {
			background: none;
		}
	}
}
</style>
<style lang="less">
.left-transfer-menu {
	width: 150px !important;
	max-height: unset !important;
	margin-top: -14px !important;
	margin-left: 30px !important;
	padding: 0 !important;
	background-color: #fff !important;
	&-title {
		background-color: @primary-color;
		color: #ffffff !important;
		line-height: 53px !important;
		font-weight: bold !important;
		text-align: center !important;
		height: 53px !important;
		padding: 0 !important;
		a {
			color: #ffffff;
		}
		a:hover {
			color: #ffffff;
		}
	}
	&:hover {
		background-color: #fff !important;
	}
	.ivu-dropdown-item {
		padding: 14px 16px !important;
	}
}
</style>

<template>
	<Menu
		v-if="storeMenu.leftMenuMode == 'default'"
		ref="leftMenu"
		theme="dark"
		:open-names="leftMenuOpenNames"
		:active-name="storeMenu.leftMenuActiveName"
		width="200"
		class="left-menu-a"
		@on-select="handleClick1"
	>
		<template v-for="(item, index) in leftMenuList">
			<template v-if="item.top == storeMenu.topActiveName && storeBase.checkPurview(item.purview)">
				<template v-if="item.type == 'submenu'">
					<Submenu :name="item.alias" class="left-submenu-1">
						<template #title>
							<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" class="left-icon" />
							<Icon v-else-if="item.icon" :type="item.icon" class="left-icon" />
							{{ item.title }}
						</template>
						<template v-for="(item2, index2) in item.menus">
							<template v-if="storeBase.checkPurview(item2.purview)">
								<template v-if="item2.type == 'submenu'">
									<Submenu :name="item.alias + '-' + item2.alias" class="left-submenu-2">
										<template #title>
											<Icon v-if="item2.customIcon" :custom="'iconfont ' + item2.customIcon" class="left-icon" />
											<Icon v-else-if="item2.icon" :type="item2.icon" class="left-icon" />
											{{ item2.title }}
										</template>
										<template v-for="(item3, index3) in item2.menus">
											<template v-if="storeBase.checkPurview(item3.purview)">
												<MenuItem :name="item.alias + '-' + item2.alias + '-' + item3.alias" :to="item3.link" :target="toTarget" class="left-menu-3">
													<Icon v-if="item3.customIcon" :custom="'iconfont ' + item3.customIcon" class="left-icon" />
													<Icon v-else-if="item3.icon" :type="item3.icon" class="left-icon" />
													{{ item3.title }}
												</MenuItem>
											</template>
										</template>
									</Submenu>
								</template>
								<template v-else>
									<MenuItem :name="item.alias + '-' + item2.alias" :to="item2.link" :target="toTarget" class="left-menu-2">
										<Badge :count="badgeCount(item2.badge)" :offset="[3, -20]">
											<Icon v-if="item2.customIcon" :custom="'iconfont ' + item2.customIcon" class="left-icon" />
											<Icon v-else-if="item2.icon" :type="item2.icon" class="left-icon" />
											<span v-else style=" display: inline-block; width: 15px; height: 10px;"></span>
											{{ item2.title }}
										</Badge>
									</MenuItem>
								</template>
							</template>
						</template>
					</Submenu>
				</template>
				<template v-else>
					<MenuItem :name="item.alias" :to="item.link" :target="toTarget" class="left-menu-1">
						<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" class="left-icon" />
						<Icon v-else-if="item.icon" :type="item.icon" class="left-icon" />
						{{ item.title }}
					</MenuItem>
				</template>
			</template>
		</template>
	</Menu>
	<Menu v-else-if="storeMenu.leftMenuMode == 'small'" theme="dark" width="78" class="left-menu-b">
		<template v-for="(item, index) in leftMenuList">
			<template v-if="item.top == storeMenu.topActiveName && storeBase.checkPurview(item.purview)">
				<MenuItem :name="item.alias" class="left-menu-1">
					<Dropdown placement="right-start" transfer transfer-class-name="left-transfer-menu" @on-click="handleClick2">
						<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" size="24" />
						<Icon v-else-if="item.icon" :type="item.icon" size="24" />
						<template #list>
							<template v-if="item.type == 'submenu'">
								<div class="left-transfer-menu-title">
									<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" size="18" />
									<Icon v-else-if="item.icon" :type="item.icon" size="18"></Icon>
									{{ item.title }}
								</div>
								<DropdownMenu>
									<template v-for="(item2, index2) in item.menus">
										<template v-if="storeBase.checkPurview(item2.purview)">
											<template v-if="item2.type == 'submenu'">
												<Dropdown transfer placement="right-start">
													<DropdownItem>
														{{ item2.title }}
														<Icon type="ios-arrow-forward" size="18"></Icon>
													</DropdownItem>
													<template #list>
														<DropdownMenu>
															<template v-for="(item3, index3) in item2.menus">
																<template v-if="storeBase.checkPurview(item3.purview)">
																	<DropdownItem :name="item.alias + '-' + item2.alias + '-' + item3.alias">{{ item3.title }}</DropdownItem>
																</template>
															</template>
														</DropdownMenu>
													</template>
												</Dropdown>
											</template>
											<template v-else>
												<DropdownItem :name="item.alias + '-' + item2.alias">
													<router-link :to="item2.link" :target="toTarget">
														<Icon v-if="item2.icon" :type="item2.icon" size="18"></Icon>
														{{ item2.title }}
													</router-link>
												</DropdownItem>
											</template>
										</template>
									</template>
								</DropdownMenu>
							</template>
							<template v-else>
								<div class="left-transfer-menu-title">
									<router-link :to="item.link" :target="toTarget">
										<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" size="18" />
										<Icon v-else-if="item.icon" :type="item.icon" size="18"></Icon>
										{{ item.title }}
									</router-link>
								</div>
							</template>
						</template>
					</Dropdown>
				</MenuItem>
			</template>
		</template>
	</Menu>
</template>

<script>
import { storeBase } from '@/store/base';
import { storeMenu } from '@/store/menu';
import { leftMenuList, menuMap } from '@/common/menu';
export default {
	name: 'LeftMenu',
	data() {
		return {
			storeBase: storeBase(),
			storeMenu: storeMenu(),
			leftMenuList: leftMenuList,
		};
	},
	computed: {
		leftMenuOpenNames() {
			let result = [];
			for (let i in this.leftMenuList) {
				let item = this.leftMenuList[i];
				if (item.top == this.storeMenu.topActiveName) {
					result.push(item.alias);
				}
			}
			return result;
		},
		toTarget() {
			return this.storeBase.keyboard.ctrl ? '_blank' : '_self';
		},
	},
	watch: {
		'storeMenu.handler'() {
			this.$nextTick(() => {
				this.$refs.leftMenu?.updateOpened();
				this.$refs.leftMenu?.updateActiveName();
			});
		},
	},
	methods: {
		badgeCount(badgeName) {
			if (badgeName) {
				return this.storeBase.badgeMap[badgeName] || 0;
			}
			return 0;
		},
		handleClick1(name) {
			console.log(name);
		},
		handleClick2(name) {
			console.log(name);
		},
	},
};
</script>

let router = [{
	path: 'room',
	name: 'room',
	meta: {
		title: '用户列表'
	},
	component: () => import('@/views/user/room/Tree.vue')
}, {
	path: 'warning-insufficient_balance',
	name: 'warning-insufficient_balance',
	meta: {
		title: '余额不足'
	},
	component: () => import('@/views/user/room/insufficientBalance.vue')
}, {
	path: 'warning-unused_water',
	name: 'warning-unused_water',
	meta: {
		title: '连续未用水'
	},
	component: () => import('@/views/user/room/unusedWater.vue')
}, {
	path: 'room_map',
	name: 'room_map',
	meta: {
		title: '用户地图'
	},
	component: () => import('@/views/user/room/Map.vue')
}, {
	path: 'room_log',
	name: 'room_log',
	meta: {
		title: '操作日志'
	},
	component: () => import('@/views/log/roomLog/List.vue')
}, {
	path: 'meter_collect',
	name: 'meter_collect',
	meta: {
		title: '用户抄表'
	},
	component: () => import('@/views/record/collectRecord/CollectList.vue')
}, {
	path: 'collect_record',
	name: 'collect_record',
	meta: {
		title: '历史抄收'
	},
	component: () => import('@/views/record/collectRecord/Tree.vue')
}, {
	path: 'water_record',
	name: 'water_record',
	meta: {
		title: '详细用水'
	},
	component: () => import('@/views/record/waterRecord/Tree.vue')
}, {
	path: 'water_bill',
	name: 'water_bill',
	meta: {
		title: '用水账单'
	},
	component: () => import('@/views/trade/waterBill/List.vue')
}, {
	path: 'charge_record',
	name: 'charge_record',
	meta: {
		title: '充值记录'
	},
	component: () => import('@/views/record/device10Record/Tree.vue')
}, {
	path: 'refund_record',
	name: 'refund_record',
	meta: {
		title: '退款记录'
	},
	component: () => import('@/views/trade/refundRecord/Tree.vue')
}, {
	path: 'room_card',
	name: 'room_card',
	meta: {
		title: '业主卡'
	},
	component: () => import('@/views/user/roomCard/List.vue')
}, {
	path: 'trial_record',
	name: 'trial_record',
	meta: {
		title: '试用记录'
	},
	component: () => import('@/views/record/trialRecord/Tree.vue')
}];
export default router;
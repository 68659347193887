const router = [{
	path: 'device_map',
	name: 'device_map',
	meta: {
		title: '设备地图'
	},
	component: () => import('@/views/device/device/Map.vue')
}, {
	path: 'device-m8',
	name: 'device-m8',
	meta: {
		title: '直饮机',
	},
	component: () => import('@/views/device/device8/Tree.vue')
}, {
	path: 'device-m9',
	name: 'device-m9',
	meta: {
		title: '采集器',
	},
	component: () => import('@/views/device/device9/Tree.vue')
}, {
	path: 'device-m10',
	name: 'device-m10',
	meta: {
		title: '有线水表',
	},
	component: () => import('@/views/device/device10/Tree.vue')
}, {
	path: 'device-m10nb',
	name: 'device-m10nb',
	meta: {
		title: 'NB水表',
	},
	component: () => import('@/views/device/device10/NBTree.vue')
}, {
	path: 'device-core',
	name: 'device-core',
	meta: {
		title: '中央净水器',
		hideBreadcrumb: true
	},
	component: () => import('@/views/device/coreDevice/index.vue')
}, {
	path: 'device-m11',
	name: 'device-m11',
	meta: {
		title: 'plc',
	},
	component: () => import('@/views/device/device11/Tree.vue')
}, {
	path: 'device-m11/boxPoint',
	name: 'device-m11__boxPoint',
	meta: {
		title: 'PLC设备监控点位',
	},
	component: () => import('@/views/device/device11/BoxPointList.vue')
}, {
	path: 'warning-m8',
	name: 'warning-m8',
	meta: {
		title: '直饮机报警',
	},
	component: () => import('@/views/device/device8/Warning.vue')
}, {
	path: 'warning-m9',
	name: 'warning-m9',
	meta: {
		title: '采集器报警',
	},
	component: () => import('@/views/device/device9/Warning.vue')
}, {
	path: 'warning-m10',
	name: 'warning-m10',
	meta: {
		title: '有线水表报警',
	},
	component: () => import('@/views/device/device10/Warning.vue')
}, {
	path: 'warning-m10nb',
	name: 'warning-m10nb',
	meta: {
		title: 'NB水表报警',
	},
	component: () => import('@/views/device/device10/NBWarning.vue')
}, {
	path: 'warning-m11',
	name: 'warning-m11',
	meta: {
		title: 'PLC报警',
	},
	component: () => import('@/views/device/device11/Warning.vue')
}, {
	path: 'work_order',
	name: 'work_order',
	meta: {
		title: '故障报修'
	},
	component: () => import('@/views/device/workOrder/Tree.vue')
}, {
	path: 'device_log',
	name: 'device_log',
	meta: {
		title: '操作日志'
	},
	component: () => import('@/views/log/deviceLog/List.vue')
}, {
	path: 'device_cmd',
	name: 'device_cmd',
	meta: {
		title: '指令日志'
	},
	component: () => import('@/views/device/deviceCmd/List.vue')
}, {
	path: 'device_warning',
	name: 'device_warning',
	meta: {
		title: '报警记录'
	},
	component: () => import('@/views/log/deviceWarningLog/List.vue')
}, {
	path: 'model',
	name: 'model',
	meta: {
		title: '设备型号'
	},
	component: () => import('@/views/device/model/List.vue')
}, {
	path: 'model/config',
	name: 'model_config',
	meta: {
		title: '设备型号配置'
	},
	component: () => import('@/views/device/modelConfig/List.vue')
}, {
	path: 'ota_setting',
	name: 'ota_setting',
	meta: {
		title: '设备固件'
	},
	component: () => import('@/views/device/otaSetting/List.vue')
}, {
	path: 'ota_setting/log',
	name: 'ota_setting__log',
	meta: {
		title: '固件更新记录列表'
	},
	component: () => import('@/views/device/otaSetting/OtaSettingLogList.vue')
}, {
	path: 'device_codes',
	name: 'device_codes',
	meta: {
		title: '三码表'
	},
	component: () => import('@/views/device/deviceCodes/List.vue')
}, {
	path: 'device_batch_record',
	name: 'device_batch_record',
	meta: {
		title: '导入设备'
	},
	component: () => import('@/views/device/deviceBatchRecord/List.vue')
}, {
	path: 'test-s1',
	name: 'test-s1',
	meta: {
		title: '编号写入'
	},
	component: () => import('@/views/device/test/Index1.vue')
}, {
	path: 'test-s2',
	name: 'test-s2',
	meta: {
		title: '电控测试'
	},
	component: () => import('@/views/device/test/Index2.vue')
}, {
	path: 'test-s3',
	name: 'test-s3',
	meta: {
		title: '组装测试'
	},
	component: () => import('@/views/device/test/Index3.vue')
}, {
	path: 'test-log',
	name: 'test-log',
	meta: {
		title: '测试记录'
	},
	component: () => import('@/views/log/testDeviceLog/List.vue')
}]
export default router
import {
	Notice
} from 'view-ui-plus'
import {
	storeBase
} from '@/store/base'
import Broadcast from '@/utils/broadcast';

let ws;
let baseData = {};
let deviceMap = {};
let reconnectLock = false;
let reconnectTimer;

const config = {
	type: {
		// 基础类型
		'LOGIN': 1001, // 客户端发送请求登录服务器
		'RE_LOGIN': 1002, // 服务器回复客户端登录请求
		'LOGOUT': 1003, // 客户端发送请求退出登录
		'RE_LOGOUT': 1004, // 服务器回复客户端退出登录请求
		'HEART': 1005, // 客户端发送心跳
		'RE_HEART': 1006, // 服务器回复客户端心跳
		// 客户端请求类型
		'SUBSCRIBE': 2001, // 客户端请求订阅设备数据
		'RE_SUBSCRIBE': 2002, // 服务器回复客户端订阅请求
		'UNSUBSCRIBE': 2003, // 客户端取消订阅设备数据
		'RE_UNSUBSCRIBE': 2004, // 服务器回复客户端订阅取消
		// 设备端上行类型
		'DEVICE_STATUS': 3001, // 设备发送设备状态到客户端
		'RE_DEVICE_STATUS': 3002, // 客户端回复设备状态已接收
	}
};

//心跳
const heartObj = {
	timeout: 30000,
	timer: null,
	serverTimer: null,
	stop() {
		// console.log('> WebSocket::heart.stop()');
		if (this.timer) {
			clearTimeout(this.timer);
		}
		if (this.serverTimer) {
			clearTimeout(this.serverTimer);
		}
		return this;
	},
	start() {
		this.stop();
		// console.log('> WebSocket::heart.start()');
		if (ws && ws.readyState == 1) {
			this.timer = setTimeout(() => {
				// console.log("> WebSocket::heart.ping");
				api.heart();
				this.serverTimer = setTimeout(() => {
					api.unconnect();
				}, this.timeout);
			}, this.timeout);
		}
	}
}

const api = {
	connect() {
		if (ws && ws.readyState == 1) {
			console.log("> WebSocket已初始化!");
			return;
		}
		const store = storeBase();
		if (!store.token) {
			console.log("未登录!");
			return;
		}
		if ("WebSocket" in window) {
			ws = new WebSocket(window.location.origin.replaceAll(/^http/ig, 'ws') + import.meta.env
				.APP_WEBSOCKET_BASE_URL);
		} else if ("MozWebSocket" in window) {
			ws = new MozWebSocket(window.location.origin.replaceAll(/^http/ig, 'ws') + import.meta.env
				.APP_WEBSOCKET_BASE_URL);
		} else {
			console.log("您的浏览器不支持 WebSocket!");
			return;
		}
		console.log("> 初始化WebSocket!");
		baseData = {
			accessToken: store.token,
			uuid: store.getUUID(),
			f: import.meta.env.APP_CLIENT,
			v: import.meta.env.APP_VERSION,
			c: import.meta.env.APP_VERSION_CODE,
			l: store.getLang()
		};
		ws.onopen = (event) => {
			console.log('> WebSocket: onopen', event);
			this.login();
		};
		ws.onclose = (event) => {
			console.log('> WebSocket: onclose', event);
			heartObj.stop();
		};
		ws.onmessage = (event) => {
			console.log('> WebSocket: onmessage', event);
			if (event.data) {
				var result = JSON.parse(event.data);
				if (result) {
					if (result.success) {
						if (result.type == config.type.RE_LOGIN) {
							baseData.clientId = result.clientId;
							heartObj.start();
						} else if (result.type == config.type.RE_HEART) {
							heartObj.start();
						} else if (result.type == config.type.DEVICE_STATUS) {
							this.send({
								type: config.type.RE_DEVICE_STATUS
							});
							result.data.deviceNo = result.deviceNo;
							Broadcast.emit(Broadcast.key.DEVICE_STATUS, result.data);
						} else {

						}
					}
				}
			}
		};
		ws.onerror = (event) => {
			console.log('> WebSocket: onerror', event);
			heartObj.stop();
			if (import.meta.env.MODE == 'test') {
				Notice.error({
					title: 'error',
					desc: '数据传输发生错误'
				})
			}
			this.reconnect();
		}
		//监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
		window.onbeforeunload = () => {
			this.unconnect();
		};
	},
	reconnect() {
		if (reconnectLock) {
			return;
		};
		reconnectLock = true;
		reconnectTimer && clearTimeout(reconnectTimer);
		reconnectTimer = setTimeout(() => {
			console.log('> WebSocket: reconnect');
			this.connect();
			reconnectLock = false;
		}, 5000);
	},
	unconnect() {
		console.log('> WebSocket: unconnect');
		if (ws && ws.readyState == 1) {
			// 取消所有订阅
			for (let k in deviceMap) {
				console.log('> deviceNo:' + k + ' = ' + deviceMap[k])
			}
			// 登出
			this.logout();
			// 断开连接
			ws.close();
		}
	},
	getWebSocket() {
		return ws;
	},
	getStatus() {
		if (ws) {
			if (ws.readyState == 0) {
				return "未连接";
			} else if (ws.readyState == 1) {
				return "已连接";
			} else if (ws.readyState == 2) {
				return "连接正在关闭";
			} else if (ws.readyState == 3) {
				return "连接已关闭";
			}
		}
		return "未初始化";
	},
	send(data) {
		Object.assign(data, baseData);
		console.log("> WebSocket: send", data);
		if (ws && ws.readyState == 1) {
			ws.send(JSON.stringify(data));
		}
	},
	// 心跳
	heart() {
		// console.log('> WebSocket: heart');
		this.send({
			type: config.type.HEART
		});
	},
	// 登录
	login() {
		// console.log('> WebSocket: login');
		this.send({
			type: config.type.LOGIN
		});
	},
	// 登出
	logout() {
		// console.log('> WebSocket: logout');
		this.send({
			type: config.type.LOGOUT
		});
	},
	/**
	 * 订阅设备
	 * @param {Object} deviceNo	设备编号
	 */
	subscribe(deviceNo) {
		// console.log('> WebSocket: subscribe');
		if (deviceMap[deviceNo]) {
			deviceMap[deviceNo]++;
		} else {
			deviceMap[deviceNo] = 1;
			this.send({
				type: config.type.SUBSCRIBE,
				deviceNo: deviceNo
			});
		}
	},
	/**
	 * 取消订阅设备
	 * @param {Object} deviceNo	设备编号
	 */
	unsubscribe(deviceNo) {
		// console.log('> WebSocket: unsubscribe');
		if (deviceMap[deviceNo]) {
			delete deviceMap[deviceNo];
			this.send({
				type: config.type.UNSUBSCRIBE,
				deviceNo: deviceNo
			});
		}
	}
}
export default api;
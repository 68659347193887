<style lang="less" scoped>
.left-menu {
	overflow-x: hidden;
	overflow-y: auto;
	background: #1b1c22 url(@/assets/bg_sidebar.png) no-repeat top left;
}
</style>

<template>
	<div class="left-menu" :style="{ height: storeBase.siderHeight + 'px' }"><LeftMenu /></div>
</template>

<script>
import { storeBase } from '@/store/base';
import LeftMenu from '@/layout/components/LeftMenu';
export default {
	name: 'SiderLayout',
	components: {
		LeftMenu,
	},
	data() {
		return {
			storeBase: storeBase(),
		};
	},
	computed: {},
	methods: {},
};
</script>

import {
	defineStore
} from "pinia";

export const storeDevice = defineStore("storeDevice", {
	state: () => {
		return {
			modelList: [],
			modelConfigList: [],
		};
	},
	persist: {
		enabled: false
	},
	getters: {
		modelConfigMap() {
			let result = {};
			for (let i in this.modelConfigList) {
				let item = this.modelConfigList[i];
				result[item.modelSid + '__' + item.configType + '__' + item.configAlias] = item;
			}
			return result;
		}
	},
	actions: {
		getModelConfigMap(modelSid, configType) {
			let result = {};
			if (modelSid > 0) {
				for (let i in this.modelConfigList) {
					let item = this.modelConfigList[i];
					if (item.modelSid == modelSid && item.configType == configType) {
						result[item.configAlias] = item;
					}
				}
			}
			return result;
		},
		getModelConfig(modelSid, configType, configAlias) {
			return this.modelConfigMap[modelSid + '__' + configType + '__' + configAlias];
		}
	},
});
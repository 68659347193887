import mitt from 'mitt';
const emitter = mitt();
// export default emitter;

/*
// 订阅一个具体的事件
emitter.on('foo', e => console.log('foo', e) )

// 订阅所有事件
emitter.on('*', (type, e) => console.log(type, e) )

// 发布一个事件
emitter.emit('foo', { a: 'b' })

// 根据订阅的函数来取消订阅
function onFoo() {}
emitter.on('foo', onFoo)   // listen
emitter.off('foo', onFoo)  // unlisten

// 只传一个参数，取消订阅同名事件
emitter.off('foo')  // unlisten

// 取消所有事件
emitter.all.clear()
*/

const key = {
	"DEVICE_STATUE": "device::device_status",
	"DEVICE_CMD_STATE": "device::cmd_state",
};

const on = (type, handler) => {
	emitter.on(type, handler);
};

const off = (type, handler) => {
	emitter.off(type, handler);
};

const emit = (type, event) => {
	emitter.emit(type, event);
};

export default {
	key,
	on,
	off,
	emit
}
import {
	createRouter,
	createWebHistory
} from 'vue-router'
import MainLayout from '@/layout/MainLayout.vue'
import Device from './device';
import User from './user';
import Marketing from './marketing';
import Finance from './finance';
import Report from './report';
import Partner from './partner';
import System from './system';
import Demo from './demo';

const routes = [{
	path: '/',
	redirect: '/login'
}, {
	path: '/login',
	name: 'login',
	meta: {
		title: '登录'
	},
	component: () => import('@/views/Login.vue')
}, {
	path: '/admin',
	name: 'admin-login',
	meta: {
		title: '运维登录'
	},
	component: () => import('@/views/Login.vue')
}, {
	path: '/agent',
	name: 'agent-login',
	meta: {
		title: '代理商登录'
	},
	component: () => import('@/views/Login.vue')
}, {
	path: '/op',
	name: 'operator-login',
	meta: {
		title: '运营商登录'
	},
	component: () => import('@/views/Login.vue')
}, {
	path: '/exception/',
	name: 'exception',
	meta: {
		title: '错误页'
	},
	component: MainLayout,
	children: [{
		path: '403',
		name: 'exception403',
		component: () => import('@/views/exception/403.vue')
	}, {
		path: '404',
		name: 'exception404',
		component: () => import('@/views/exception/404.vue')
	}, {
		path: '500',
		name: 'exception500',
		component: () => import('@/views/exception/500.vue')
	}]
}, {
	path: '/',
	name: 'console',
	component: MainLayout,
	children: [{
		path: 'console',
		name: 'console',
		meta: {
			hideBreadcrumb: true,
			autoWidth: true
		},
		component: () => import('@/views/Console.vue')
	}, {
		path: 'console/changepwd',
		name: 'changepwd',
		component: () => import('@/views/Changepwd.vue')
	}]
}, {
	path: '/screen/',
	redirect: '/screen/'
}, {
	path: '/device',
	name: 'device',
	component: MainLayout,
	children: Device
}, {
	path: '/user',
	name: 'user',
	component: MainLayout,
	children: User
}, {
	path: '/marketing',
	name: 'marketing',
	component: MainLayout,
	children: Marketing
}, {
	path: '/finance',
	name: 'finance',
	component: MainLayout,
	children: Finance
}, {
	path: '/report',
	name: 'report',
	component: MainLayout,
	children: Report
}, {
	path: '/partner',
	name: 'partner',
	component: MainLayout,
	children: Partner
}, {
	path: '/system',
	name: 'system',
	component: MainLayout,
	children: System
}, {
	path: '/demo',
	name: 'demo',
	component: MainLayout,
	children: Demo
}]

const router = createRouter({
	routes,
	history: createWebHistory(import.meta.env.APP_BASE_PATH),
	scrollBehavior() {
		return {
			top: 0
		}
	}
})

export default router
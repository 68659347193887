import {
	createApp
} from 'vue'
import ViewUIPlus from 'view-ui-plus'
import './styles/custom.less'
import App from './App.vue'
import router from './router'
import pinia from './store/index'
// import './mock'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
	Prism,
});

const app = createApp(App)

import './utils/interceptor'

app.use(router)
	.use(pinia)
	.use(ViewUIPlus)
	.use(VueMarkdownEditor)
	.mount('#app')
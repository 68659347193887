import {
	defineStore
} from "pinia";
import {
	random,
	checkPurview
} from '../utils/util.js'

export const storeBase = defineStore("storeBase", {
	state: () => {
		return {
			uuid: '',
			token: '',
			userType: 3,
			userinfo: {},
			language: 'cn',
			clientWidth: 960,
			clientHeight: 550,
			projectName: '',
			assetsPrePath: '',
			mainTreeLeftShow: true,
			mainTreeLeftWidth: 0.2,
			keyboard: {
				ctrl: false,
				shift: false,
				alt: false
			},
			badgeMap: {
				dataTime: 0
			}
		};
	},
	persist: {
		enabled: true,
		// 持久化参数
		strategies: [{
			key: 'sfcloud_manage_base',
			// 存储方式，默认sessionStorage
			storage: localStorage,
			// 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
			paths: [
				'uuid',
				'token',
				'userType',
				'userinfo',
				'language',
				'mainTreeLeftShow',
				'mainTreeLeftWidth'
			],
		}]
	},
	getters: {
		mainHeight() {
			return this.clientHeight - 136;
		},
		siderHeight() {
			return this.clientHeight - 64;
		}
	},
	actions: {
		getUUID() {
			if (!this.uuid) this.uuid = random(32)
			return this.uuid
		},
		getLang() {
			return this.language;
		},
		checkPurview(keys) {
			if (keys) {
				if (keys == '___is_root___') {
					return this.userinfo?.isRoot == 1;
				} else if (keys == '___is_partner___') {
					return this.userinfo?.isPartner == 1;
				} else if (keys == '___login___') {
					return this.token != '';
				} else if (keys.constructor == String) {
					return checkPurview(this.userinfo.purview, keys);
				} else if (keys.constructor == Array) {
					let hasPurview = false;
					for (let i in keys) {
						let item = keys[i];
						if (item.constructor == Array) {
							if (item.length > 0) {
								hasPurview = checkPurview(this.userinfo.purview, item[0]);
								if (!hasPurview) continue;
								if (item.length > 1) {
									if (item[1].constructor == Array) {
										for (let j = 0; j < item[1].length; j++) {
											hasPurview = item[1][j] == this.userType;
											// console.log('check:', item[0], item[1][j], hasPurview)
											if (hasPurview) break;
										}
									} else {
										hasPurview = item[1] == this.userType;
										// console.log('check:', item[0], item[1], hasPurview)
									}
									if (hasPurview) break;
								}
							}
						} else if (item == '___is_root___') {
							return this.userinfo?.isRoot == 1;
						} else if (item == '___is_partner___') {
							return this.userinfo?.isPartner == 1;
						} else if (item == '___login___') {
							return this.token != '';
						} else {
							hasPurview = checkPurview(this.userinfo.purview, item);
							if (hasPurview) break;
						}
					}
					return hasPurview;
				} else {
					console.log('base.checkPurview 参数错误')
				}
			}
			return false;
		},
		checkUserType(userType) {
			if (userType) {
				if (userType.constructor == Number) {
					return userType == this.userType;
				} else if (userType.constructor == Array) {
					let result = false;
					for (let i in userType) {
						result = userType[i] == this.userType;
						if (result) break;
					}
					return result;
				} else {
					console.log('base.checkUserType 参数错误')
				}
			} else {
				return true;
			}
		},
		updateKeyboard(e, flag) {
			if (e.keyCode === 16) {
				this.keyboard.shift = flag;
			} else if (e.keyCode === 17) {
				this.keyboard.ctrl = flag;
			} else if (e.keyCode === 18) {
				this.keyboard.alt = flag;
			}
		}
	},
});
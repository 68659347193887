import {
	chain
} from 'mathjs';

// 设置页面标题
export const updateWindowTitle = (title, project) => {
	window.document.title = (title ? title + " - " : "") + (project || '') +
		import.meta.env.APP_TITLE;
}
// 生成随机字符串
export function random(n, chars) {
	var str = '';
	n = n || 32;
	chars = chars || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
	//chars = chars || 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	var maxPos = chars.length;
	for (var i = 0; i < n; i++) {
		str += chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return str;
}

export function timeFix() {
	const time = new Date()
	const hour = time.getHours()
	return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

// 格式化日期
const week = {
	"0": "/u65e5",
	"1": "/u4e00",
	"2": "/u4e8c",
	"3": "/u4e09",
	"4": "/u56db",
	"5": "/u4e94",
	"6": "/u516d"
};
export function timeFormat(time, fmt) {
	if (time) {
		var date = new Date(time);
		fmt = fmt || 'yyyy-MM-dd HH:mm:ss';
		var o = {
			"M+": date.getMonth() + 1, // 月份
			"d+": date.getDate(), // 日
			"h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 小时
			"H+": date.getHours(), // 小时
			"m+": date.getMinutes(), // 分
			"s+": date.getSeconds(), // 秒
			"q+": Math.floor((date.getMonth() + 3) / 3), // 季度
			"S": date.getMilliseconds() // 毫秒
		};
		if (/(y+)/.test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
		}
		if (/(E+)/.test(fmt)) {
			fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" :
				"/u5468") : "") + week[date.getDay() + ""]);
		}
		for (var k in o) {
			if (new RegExp("(" + k + ")").test(fmt)) {
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" +
					o[k]).length)));
			}
		}
		return fmt;
	}
	return '';
}
// 格式化
export function toFixed(num1, num2, def) {
	if (num1 !== undefined) {
		return num1.toFixed(num2);
	} else {
		return def;
	}
}
// 格式化人民币显示
export function moneyFormat(cash, num) {
	let result = chain(cash || 0)
		.divide(100)
		.done();
	return num > 0 ? result.toFixed(num) : result;
}
// 人民币元转分
export function moneyConvert(cash) {
	return chain(cash || 0)
		.multiply(100)
		.round(0)
		.done();
}
// 格式化水量显示
export function volumeFormat(cash, num) {
	let result = chain(cash || 0)
		.divide(1000)
		.done();
	return num > 0 ? result.toFixed(num) : result;
}
// 水量升转毫升
export function volumeConvert(cash) {
	return chain(cash || 0)
		.multiply(1000)
		.round(0)
		.done();
}

/**
 * 检查权限
 * @param String purviews	拥有权限
 * @param String keys		待检查权限
 */
export function checkPurview(purviews, keys) {
	if (purviews && keys) {
		purviews = ',' + purviews + ',';
		if (keys.indexOf('&') < 0) {
			return purviews.indexOf(',' + keys + ',') > -1;
		} else {
			let purviewArray = keys.split('&');
			for (let i in purviewArray) {
				console.log(i)
				if (purviewArray[i] && purviews.indexOf(purviewArray[i]) < 0)
					return false;
			}
			return true;
		}
	}
	return false;
}

/**
 *
 * @description 防抖
 * @param fn  需要频繁调用的方法
 * @param delay 间隔时间，表示多长时间没有操作则执行fn方法, delay单位是毫秒
 * 
 */
export const debounce = (fn, delay = 300) => {
	let timer = null;
	return function(...args) {
		if (timer != null) {
			clearTimeout(timer)
			timer = null
		}
		timer = setTimeout(() => {
			fn(this, ...args);
		}, delay);
	}
}

export function download(url, name) {
	if (url) {
		let link = document.createElement('a');
		link.style.display = 'none';
		link.href = url
		if (name) {
			link.setAttribute('download', name);
		}
		document.body.appendChild(link);
		link.click();
	}
}
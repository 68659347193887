let router = [{
	path: 'manage-partner_list',
	name: 'manage-partner_list',
	meta: {
		title: '合伙人列表'
	},
	component: () => import('@/views/partner/List.vue')
}, {
	path: 'manage-partner_rate',
	name: 'manage-partner_rate',
	meta: {
		title: '分成比例'
	},
	component: () => import('@/views/partner/Rate.vue')
}, {
	path: 'manage-trade_list2',
	name: 'manage-trade_list2',
	meta: {
		title: '线上分账明细'
	},
	component: () => import('@/views/partner/trade/Tree2.vue')
}, {
	path: 'manage-bill_list2',
	name: 'manage-bill_list2',
	meta: {
		title: '线上结算单'
	},
	component: () => import('@/views/partner/bill/List2.vue')
}, {
	path: 'manage-trade_list1',
	name: 'manage-trade_list1',
	meta: {
		title: '线下分账明细'
	},
	component: () => import('@/views/partner/trade/Tree1.vue')
}, {
	path: 'manage-bill_list1',
	name: 'manage-bill_list1',
	meta: {
		title: '线下结算单'
	},
	component: () => import('@/views/partner/bill/List1.vue')
}, {
	path: 'ship-index',
	name: 'ship-index',
	meta: {
		title: '资金帐号',
		autoWidth: true
	},
	component: () => import('@/views/partner/ship/Index.vue')
}, {
	path: 'ship-withdraw_list',
	name: 'ship-withdraw_list',
	meta: {
		title: '线上提现记录'
	},
	component: () => import('@/views/partner/ship/withdraw/List.vue')
}, {
	path: 'ship-trade_list2',
	name: 'ship-trade_list2',
	meta: {
		title: '线上分账明细'
	},
	component: () => import('@/views/partner/ship/trade/Tree2.vue')
}, {
	path: 'ship-bill_list2',
	name: 'ship-bill_list2',
	meta: {
		title: '线下结算申请'
	},
	component: () => import('@/views/partner/ship/bill/List2.vue')
}, {
	path: 'ship-trade_list1',
	name: 'ship-trade_list1',
	meta: {
		title: '线下分账明细'
	},
	component: () => import('@/views/partner/ship/trade/Tree1.vue')
}, {
	path: 'ship-bill_list1',
	name: 'ship-bill_list1',
	meta: {
		title: '线下结算申请'
	},
	component: () => import('@/views/partner/ship/bill/List1.vue')
}];
export default router;
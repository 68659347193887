import router from '../router'
import {
	LoadingBar
} from 'view-ui-plus'
import {
	storeBase
} from '@/store/base'
import {
	storeMenu
} from '@/store/menu'
import {
	updateWindowTitle
} from '@/utils/util'
import Websocket from '@/utils/websocket';
const allowList = ['login', 'admin-login', 'agent-login', 'operator-login']
const loginRoutePathList = ['/login', '/admin', '/agent', '/op']
const loginRoutePath = '/login'
const defaultRoutePath = '/console/'
const exception404RoutePath = '/exception/404'

router.beforeEach((to, from, next) => {
	console.log('beforeEach.from: ', from)
	console.log('beforeEach.to: ', to)
	const store = storeBase();
	updateWindowTitle(to.meta?.title, store.projectName);
	LoadingBar.start()
	if (to.name) {
		if (store.token) {
			if (loginRoutePathList.includes(to.path)) {
				next({
					path: defaultRoutePath
				})
				LoadingBar.finish()
			} else {
				next()
			}
			Websocket.connect();
		} else {
			if (allowList.includes(to.name)) {
				if (to.name == 'admin-login') {
					store.userType = 1;
				} else if (to.name == 'agent-login') {
					store.userType = 2;
				} else if (to.name == 'operator-login') {
					store.userType = 3;
				}
				next()
			} else {
				next({
					path: loginRoutePath
				})
				LoadingBar.finish()
			}
		}
	} else {
		next({
			path: exception404RoutePath
		})
		LoadingBar.finish()
	}
})

router.afterEach((to, from) => {
	LoadingBar.finish()
	const store = storeMenu();
	store.calc(to.path);
	if (to.path != from.path) {
		store.buttonList = [];
	}
})
import Device from '@/common/menu/device';
import User from '@/common/menu/user';
import Report from '@/common/menu/report';
import Marketing from '@/common/menu/marketing';
import Finance from '@/common/menu/finance';
import Partner from '@/common/menu/partner';
import System from '@/common/menu/system';
import Demo from '@/common/menu/demo';
// import {
// 	submenu,
// 	groupmenu
// } from '@/common/menu/demo';

const menuList = [{
		"alias": "console",
		"customIcon": "icon-tuceng",
		"title": "首页",
		"pos": "top",
		"purview": "___login___",
		"link": "/console/",
		"menus": [{
			"icon": "",
			"title": "修改密码",
			"alias": "changepwd",
			"link": "/console/changepwd"
		}]
	}, {
		"icon": "",
		"alias": "exception",
		"title": "错误页",
		"menus": [{
			"icon": "",
			"title": "403",
			"alias": "403",
			"link": "/exception/403"
		}, {
			"icon": "",
			"title": "404",
			"alias": "404",
			"link": "/exception/404"
		}, {
			"icon": "",
			"title": "500",
			"alias": "500",
			"link": "/exception/500"
		}]
	},
	Device,
	User,
	Report,
	Marketing,
	Finance,
	Partner,
	System
];
let mode = import.meta.env.MODE;
if (mode == 'development' || mode == 'test') {
	menuList.push(Demo)
}

const menuMap = {};
const leftMenuList = [];

export {
	menuList,
	menuMap,
	leftMenuList
}
let router = [{
	path: 'index',
	name: 'index',
	meta: {
		title: '模版'
	},
	component: () => import('@/views/demo/Index.vue')
}, {
	path: 'table',
	name: 'table',
	meta: {
		title: '表单模版'
	},
	component: () => import('@/views/demo/Table.vue')
}, {
	path: 'echarts',
	name: 'echarts',
	meta: {
		title: 'echarts模版'
	},
	component: () => import('@/views/demo/Echarts.vue')
}];
export default router;
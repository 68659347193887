<style lang="less" scoped>
.layout {
	&-header {
		padding: 0 30px 0 0;
		box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
	}
	&-content {
		overflow-y: auto;
		&-main {
			background-color: #ffffff;
			margin: 0 20px 20px;
			border-radius: 5px;
			min-width: 1440px;
		}
		&-main2 {
			background: none;
			margin: 0 20px 20px;
			border-radius: 5px;
			min-width: 1640px;
		}
		&-main-auto {
			min-width: unset;
		}
	}
}
.layout-main {
	background: url(@/assets/bg_big.jpg) no-repeat top left;
	background-size: cover;
}
</style>

<template>
	<Layout class="layout" :style="{ minHeight: storeBase.clientHeight + 'px', minWidth: '1200px' }">
		<Header class="layout-header"><HeaderLayout v-on:collapsed-sider=""/></Header>
		<Layout v-if="storeMenu.leftMenuMode == 'none'" class="layout-main">
			<TopBreadcrumb />
			<Content class="layout-content" :style="{ height: mainHeight + 'px' }">
				<div
					:class="[hideBreadcrumb ? 'layout-content-main2' : 'layout-content-main', autoWidth ? 'layout-content-main-auto' : '']"
					:style="{ minHeight: mainHeight + 'px' }"
				>
					<router-view />
				</div>
			</Content>
		</Layout>
		<Layout v-else>
			<Sider class="layout-sider" hide-trigger collapsible :collapsed-width="78" v-model="siderIsCollapsed" :width="200">
				<SiderLayout />
			</Sider>
			<Layout class="layout-main">
				<TopBreadcrumb />
				<Content class="layout-content" :style="{ height: mainHeight + 'px' }">
					<div
						:class="[hideBreadcrumb ? 'layout-content-main2' : 'layout-content-main', autoWidth ? 'layout-content-main-auto' : '']"
						:style="{ minHeight: mainHeight + 'px' }"
						style="position: relative;"
					>
						<router-view />
					</div>
				</Content>
			</Layout>
		</Layout>
	</Layout>
</template>

<script>
import { storeBase } from '@/store/base';
import { storeMenu } from '@/store/menu';
import HeaderLayout from '@/layout/HeaderLayout';
import SiderLayout from '@/layout/SiderLayout';
import TopBreadcrumb from '@/layout/components/TopBreadcrumb';
export default {
	name: 'MainLayout',
	components: {
		HeaderLayout,
		SiderLayout,
		TopBreadcrumb,
	},
	data() {
		return {
			storeBase: storeBase(),
			storeMenu: storeMenu(),
		};
	},
	computed: {
		siderIsCollapsed() {
			return this.storeMenu.leftMenuMode != 'default';
		},
		hideBreadcrumb() {
			return this.$route.meta && this.$route.meta.hideBreadcrumb;
		},
		autoWidth() {
			return this.$route.meta && this.$route.meta.autoWidth;
		},
		mainHeight() {
			if (this.hideBreadcrumb) {
				return this.storeBase.mainHeight + 43;
			} else {
				return this.storeBase.mainHeight;
			}
		},
	},
	created() {},
};
</script>
